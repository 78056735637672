import axios from 'axios';

const API_BASE_URL = window.env.REACT_APP_API_BASE_URL;

class APIService {
  api: any;
  token: string | null;

  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      // You can add additional Axios configuration options here, e.g. headers, timeout, etc.
    });
    this.token = localStorage.getItem('token');
  }

  async signup(data: any): Promise<any> {
    try {
      const response = await this.api.post('auth/sign-up', data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async login(data: any) {
    try {
      const response = await this.api.post('user/send-otp', {
        email: data.universityEmail,
        student_code: data.studentId,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async verifyOtp(data: any) {
    try {
      const response = await this.api.post('user/verify-otp', {
        otp: data.otp,
        student_code: data.studentId,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async resendOtp(data: any) {
    try {
      const response = await this.api.post('student/verify', data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async getPreviews() {
    try {
      const response = await this.api.get('student/previews', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async payment(data: any) {
    try {
      const response = await this.api.post('student/payment', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async getLoggedInUser(student_code: any) {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken || undefined) {
        return false;
      }
      const response = await this.api.post(
        'user/get-profile',
        { student_code },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response;
    } catch (error) {
      localStorage.removeItem('token');

      return false;
    }
  }
}

export default new APIService();
